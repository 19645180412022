import { css, cx } from '@emotion/css';
import React, { memo, useMemo, useState } from 'react';
import { useMedia } from 'react-use';

import { GrafanaTheme2, isDateTime, rangeUtil, RawTimeRange, TimeOption, TimeRange, TimeZone } from '@grafana/data';

//import { FilterInput } from '../..';
import { stylesFactory, useTheme2 } from '../../../themes';
import { getFocusStyles } from '../../../themes/mixins';
import { CustomScrollbar } from '../../CustomScrollbar/CustomScrollbar';
import { TimeRangeContent } from '../TimeRangePicker/TimeRangeContent';
import { TimeRangeList } from '../TimeRangePicker/TimeRangeList';
import { mapOptionToTimeRange, mapRangeToTimeOption } from '../TimeRangePicker/mapper';

interface Props {
  value: TimeRange;
  onChange: (timeRange: TimeRange) => void;
  onChangeTimeZone: (timeZone: TimeZone) => void;
  onChangeFiscalYearStartMonth?: (month: number) => void;
  timeZone?: TimeZone;
  fiscalYearStartMonth?: number;
  quickOptions?: TimeOption[];
  history?: TimeRange[];
  showHistory?: boolean;
  className?: string;
  hideTimeZone?: boolean;
  /** Reverse the order of relative and absolute range pickers. Used to left align the picker in forms */
  isReversed?: boolean;
  hideQuickRanges?: boolean;
}

export interface PropsWithScreenSize extends Props {
  isFullscreen: boolean;
}

interface FormProps extends Omit<Props, 'history'> {
  historyOptions?: TimeOption[];
}

export const TimePickerContentWithScreenSize: React.FC<PropsWithScreenSize> = (props) => {
  const {
    quickOptions = [],
    isReversed,
    isFullscreen,
    hideQuickRanges,
    timeZone,
    value,
    onChange,
    history,
    showHistory,
    className,
  } = props;
  const isHistoryEmpty = !history?.length;
  const isContainerTall =
    (isFullscreen && showHistory) || (!isFullscreen && ((showHistory && !isHistoryEmpty) || !hideQuickRanges));
  const theme = useTheme2();
  const styles = getStyles(theme, isReversed, hideQuickRanges, isContainerTall);
  const historyOptions = mapToHistoryOptions(history, timeZone);
  const timeOption = useTimeOption(value.raw, quickOptions);
  const [searchTerm] = useState('');
  const filteredQuickOptions = quickOptions.filter((o) => o.display.toLowerCase().includes(searchTerm.toLowerCase()));

  const onChangeTimeOption = (timeOption: TimeOption) => {
    return onChange(mapOptionToTimeRange(timeOption));
  };

  return (
    <div id="ListTimePickerContent" className={cx(styles.container, className)}>
      <div className={styles.body}>
        {(!isFullscreen || !hideQuickRanges) && (
          <div className={cx(styles.rightSide)}>
            {/* <div className={styles.timeRangeFilter}>
              <FilterInput
                width={0}
                autoFocus={true}
                value={searchTerm}
                onChange={setSearchQuery}
                placeholder={'Search quick ranges'}
              />
            </div> */}
            <CustomScrollbar>
              {<NarrowScreenForm {...props} historyOptions={historyOptions} />}
              {!hideQuickRanges && (
                <TimeRangeList options={filteredQuickOptions} onChange={onChangeTimeOption} value={timeOption} />
              )}
            </CustomScrollbar>
          </div>
        )}
      </div>
    </div>
  );
};

export const ListTimePickerContent: React.FC<Props> = (props) => {
  const theme = useTheme2();
  const isFullscreen = useMedia(`(min-width: ${theme.breakpoints.values.lg}px)`);
  return <TimePickerContentWithScreenSize {...props} isFullscreen={isFullscreen} />;
};

export function isContainTimeRange() {
  let timeRangeCustomeKeyList = ['timerange'];
  const currentUrl = window.location.href;
  let result = false;
  timeRangeCustomeKeyList.forEach((element) => {
    if (currentUrl.includes(element)) {
      result = true;
    }
  });
  return result;
}

export function isInKioskMode() {
  let kioskKeyList = ['kiosk'];
  const currentUrl = window.location.href;
  let result = false;
  kioskKeyList.forEach((element) => {
    if (currentUrl.includes(element)) {
      result = true;
    }
  });
  return result;
}

const NarrowScreenForm: React.FC<FormProps> = (props) => {
  const { value, hideQuickRanges, onChange, timeZone, historyOptions = [], showHistory } = props;
  const theme = useTheme2();
  const styles = getNarrowScreenStyles(theme);
  const isAbsolute = isDateTime(value.raw.from) || isDateTime(value.raw.to);
  const [collapsedFlag] = useState(!isAbsolute);
  const collapsed = hideQuickRanges ? false : collapsedFlag;

  const onChangeTimeOption = (timeOption: TimeOption) => {
    return onChange(mapOptionToTimeRange(timeOption));
  };

  return (
    <fieldset>
      {!collapsed && (
        <div className={styles.body} id="expanded-timerange">
          <div className={styles.form}>
            <TimeRangeContent value={value} onApply={onChange} timeZone={timeZone} isFullscreen={false} />
          </div>
          {showHistory && (
            <TimeRangeList
              title="Recently used absolute ranges"
              options={historyOptions}
              onChange={onChangeTimeOption}
              placeholderEmpty={null}
            />
          )}
        </div>
      )}
    </fieldset>
  );
};

const EmptyRecentList = memo(() => {
  const theme = useTheme2();
  const styles = getEmptyListStyles(theme);

  return (
    <div className={styles.container}>
      <div>
        <span>
          It looks like you haven&apos;t used this time picker before. As soon as you enter some time intervals,
          recently used intervals will appear here.
        </span>
      </div>
      <div>
        <a
          className={styles.link}
          href="https://grafana.com/docs/grafana/latest/dashboards/time-range-controls"
          target="_new"
        >
          Read the documentation
        </a>
        <span> to find out more about how to enter custom time ranges.</span>
      </div>
    </div>
  );
});

function mapToHistoryOptions(ranges?: TimeRange[], timeZone?: TimeZone): TimeOption[] {
  if (!Array.isArray(ranges) || ranges.length === 0) {
    return [];
  }
  return ranges.slice(ranges.length - 4).map((range) => mapRangeToTimeOption(range, timeZone));
}

EmptyRecentList.displayName = 'EmptyRecentList';

const useTimeOption = (raw: RawTimeRange, quickOptions: TimeOption[]): TimeOption | undefined => {
  return useMemo(() => {
    if (!rangeUtil.isRelativeTimeRange(raw)) {
      return;
    }
    return quickOptions.find((option) => {
      return option.from === raw.from && option.to === raw.to;
    });
  }, [raw, quickOptions]);
};

const getStyles = stylesFactory((theme: GrafanaTheme2, isReversed, hideQuickRanges, isContainerTall) => {
  return {
    container: css`
      background: ${theme.colors.background.primary};
      box-shadow: ${theme.shadows.z3};
      position: absolute;
      z-index: ${theme.zIndex.dropdown};
      width: 262px !important;
      display: block;
      top: 116%;
      border-radius: 2px;
      border: 1px solid ${theme.colors.border.weak};
      ${isReversed ? 'left' : 'right'}: 0;
      @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
        width: 262px;
      }
    `,
    body: css`
      display: flex;
      flex-direction: row-reverse;
      height: ${isContainerTall ? '381px' : '217px'};
    `,
    leftSide: css`
      display: flex;
      flex-direction: column;
      border-right: ${isReversed ? 'none' : `1px solid ${theme.colors.border.weak}`};
      width: ${!hideQuickRanges ? '60%' : '100%'};
      overflow: hidden;
      order: ${isReversed ? 1 : 0};
    `,

    rightSide: css`
      width: 100% !important;
      border-right: ${isReversed ? `1px solid ${theme.colors.border.weak}` : 'none'};
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: ${theme.breakpoints.values.lg}px) {
        width: 100% !important;
      }
    `,
    timeRangeFilter: css`
      padding: ${theme.spacing(1)};
    `,
    spacing: css`
      margin-top: 16px;
    `,
  };
});

const getNarrowScreenStyles = stylesFactory((theme: GrafanaTheme2) => {
  return {
    header: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${theme.colors.border.weak};
      padding: 7px 9px 7px 9px;
    `,
    expandButton: css`
      background-color: transparent;
      border: none;
      display: flex;
      width: 100%;

      &:focus-visible {
        ${getFocusStyles(theme)}
      }
    `,
    body: css`
      border-bottom: 1px solid ${theme.colors.border.weak};
    `,
    form: css`
      padding: 7px 9px 7px 9px;
    `,
  };
});

const getEmptyListStyles = stylesFactory((theme: GrafanaTheme2) => {
  return {
    container: css`
      padding: 12px;
      margin: 12px;

      a,
      span {
        font-size: 13px;
      }
    `,
    link: css`
      color: ${theme.colors.text.link};
    `,
  };
});
