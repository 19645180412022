import { TimeOption } from '@grafana/data';

//Historical options
export const historicalOptions: TimeOption[] = [
  { from: 'now/d', to: 'now', display: 'Today so far' },
  { from: 'now/w', to: 'now', display: 'This week so far' },
  { from: 'now/M', to: 'now', display: 'This month so far' },
];

//Kiosk mode time range
export const shortTimeModeOptions: TimeOption[] = [
  { from: 'now-24h', to: 'now', display: 'Last 24 hours' },
  { from: 'now-2d', to: 'now', display: 'Last 2 days' },
  { from: 'now-7d', to: 'now', display: 'Last 7 days' },
  { from: 'now-30d', to: 'now', display: 'Last 30 days' },
  { from: 'now-90d', to: 'now', display: 'Last 90 days' },
];

// Can add more option here when create new time range option
